<template>
  <div class="">
    <el-dialog
      :close-on-click-modal="false"
      v-loading="listLoading"
      :visible.sync="isActiveDialogEditOrder"
      :width="width + 'px'"
      :style="cssVars"
      inline
      :before-close="handleCancel"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
      ></span>

      <div
        class="search-container-fn-input"
        style="display: flex;margin-top: 10px; margin-bottom: 10px;"
      >
        <div>
          <div class="required"></div>
          <span>收货人信息</span>
        </div>
      </div>
      <el-table
        border
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="shr"
        style="width: 100%;z-index: 0"
      >
        <el-table-column
          prop="shr_name"
          label="收货人"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                size="mini"
                v-model="scope.row.shr_name"
                placeholder="收货人姓名"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="shr_mobile"
          label="手机号"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                size="mini"
                v-model="scope.row.shr_mobile"
                placeholder="收货人手机号"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="zip_code"
          label="邮政编码"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                size="mini"
                v-model="scope.row.zip_code"
                placeholder="收货人邮编"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="region_name"
          label="省市区"
          align="center"
          width="240"
        >
          <template slot-scope="scope">
            <div
              style="display: flex; justify-content: space-around"
              class="input2"
              v-if="scope"
            >
              <el-cascader
                size="mini"
                :options="provincesOptions"
                :props="props"
                clearable
                separator=""
                ref="cascaderAddr"
                @change="handleChange"
                filterable
                :placeholder="address_deil"
              ></el-cascader>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shr_address" label="收货地址" align="center">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                type="textarea"
                :rows="1"
                size="mini"
                v-model="scope.row.shr_address"
                placeholder="收货人详细地址"
              ></el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px"></div>
      <div
        v-show="this.originOrder === 'customer'"
        class="search-container-fn-input"
        style="display: flex;"
      >
        <div>
          <div class="required"></div>
          <span>商品信息</span>
        </div>

        <div style="margin-left: auto ">
          <!-- <el-button
						v-if="isEditGoodsInfo"
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px;margin-top: 10px; margin-bottom: 10px; margin-right: 20px"
						@click="handleEditGoodsInfo">
						<i class="el-icon-s-check" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">确认修改信息</span>
					</el-button> -->
          <!-- <el-button
						v-else
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px;margin-top: 10px; margin-bottom: 10px; margin-right: 20px"
						@click="handleEditGoodsInfo">
						<i class="el-icon-edit" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">修改商品信息</span>
					</el-button> -->
          <el-button
            size="mini"
            type="primary"
            style="padding: 6px 7px 7px 8px;margin-top: 10px; margin-bottom: 10px; "
            @click="handleAddGoods"
          >
            <i class="el-icon-plus" style="font-size: 13px" />
            <span style="margin-left: 5px; font-size: 12px">增加商品</span>
          </el-button>
        </div>
      </div>
      <div style="display: flex;">
        <div
          style="margin-bottom: 10px; font-weight: bolder; font-size: 18px;margin-right:20px"
        >
          总计：<span style="color: red">¥ {{ totalAllPrice }}</span>
        </div>
        <div style="margin-bottom: 10px; font-weight: bolder; font-size: 18px;margin-right:20px">
          小计：<span style="color: red">¥ {{ totalAllPrice1 }}</span>
        </div>
				<div style="margin-bottom: 10px; font-weight: bolder; font-size: 18px">
					使用时间：<span style="color: red">{{ maxDay }}天</span>
				</div>
      </div>
      <el-table
        v-show="this.originOrder === 'customer'"
        border
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="goodInfo"
        style="width: 100%;z-index: 0"
      >
        <el-table-column
          prop="cover_pic"
          label="封面图"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 90px"
              :src="scope.row.img_url"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goods_name" label="商品名称" align="center">
        </el-table-column>

        <el-table-column prop="spec" label="规格" align="center">
        </el-table-column>
        <el-table-column prop="number" label="数量" align="center">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                @change="$forceUpdate()"
                v-model="scope.row.number"
                size="mini"
                placeholder="数量"
              >
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_price" label="单价" align="center">
        </el-table-column>
        <el-table-column prop="goods_total_price" label="总计" align="center">
          <template slot-scope="scope">
            <div>
              {{
                (
                  Number(scope.row.goods_price) * Number(scope.row.number)
                ).toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="discount_amount" label="优惠金额" align="center">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
              <el-input
                @change="
                  handleGoodInfoSinglePrice(
                    scope.index,
                    scope.row,
                    'discount_amount'
                  )
                "
                size="mini"
                v-model="scope.row.discount_amount"
                placeholder="折扣"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="小计" align="center">
          <template slot-scope="scope" v-if="scope.row.number > 0">
            <div style="display: flex; justify-content: space-around">
              {{
                (
                  Number(scope.row.number) * Number(scope.row.goods_price) -
                  Number(scope.row.discount_amount)
                ).toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>
        <!--<el-table-column align="center" width="280" label="操作" fixed="right">-->
        <!--	<template slot-scope="scope">-->
        <!--		<el-button size="mini" type="danger" icon="el-icon-delete"-->
        <!--							 style="padding: 7px 8px;margin-left: 5px;"-->
        <!--							 @click="handleDeleteGood(scope.$index, scope.row)">删除-->
        <!--		</el-button>-->
        <!--	</template>-->
        <!--</el-table-column>-->
      </el-table>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 10px; margin-bottom: 15px"
      >
        <el-button class="mgr-20" size="mini" @click="handleCancel"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!----------------添加商品对话框------------------>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      destroy-on-close
      :close-on-click-modal="false"
      width="900px"
      :before-close="handleCloseAddProduct"
    >
      <div class="required" style="margin-bottom: 10px"></div>
      <span>添加商品</span>

      <div class="search-container">
        <div class="search-container-fn-input">
          <label>商品查询：</label>
          <el-input
            style="width:200px"
            size="small"
            placeholder="商品名称"
            prefix-icon="el-icon-search"
            v-model="addProductSearchParams.keyword"
            clearable
            @change="getListProduct('restPage')"
          >
          </el-input>
        </div>
        <div style="margin-left: auto">
          <!--<el-button-->
          <!--	v-if="isEditAddGoodsDiscount"-->
          <!--	size="mini"-->
          <!--	type="success"-->
          <!--	style="padding: 6px 7px 7px 8px;margin-top: 10px; margin-bottom: 10px; margin-right: 20px"-->
          <!--	@click="handleEditAddGoodsInfo">-->
          <!--	<i class="el-icon-s-check" style="font-size: 13px"/>-->
          <!--	<span style="margin-left: 5px; font-size: 12px">确认商品</span>-->
          <!--</el-button>-->
          <!--<el-button-->
          <!--	v-else-->
          <!--	size="mini"-->
          <!--	type="primary"-->
          <!--	style="padding: 6px 7px 7px 8px;margin-top: 10px; margin-bottom: 10px; margin-right: 20px"-->
          <!--	@click="handleEditAddGoodsInfo">-->
          <!--	<i class="el-icon-edit" style="font-size: 13px"/>-->
          <!--	<span style="margin-left: 5px; font-size: 12px">修改商品</span>-->
          <!--</el-button>-->
        </div>
      </div>

      <div style="display: flex;">
        <div
          style="margin-bottom: 10px; font-weight: bolder; font-size: 18px;margin-right:20px"
        >
          总计：<span style="color: red">¥ {{ totalAddAllPrice }}</span>
        </div>

        <div style="margin-bottom: 10px; font-weight: bolder; font-size: 18px">
          小计<span style="color: red">¥ {{ totalAddAllPrice1 }}</span>
        </div>
      </div>
      <el-table
        border
        ref="multipleTable"
        v-loading="listLoading"
        :header-cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%;z-index: 0"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="cover_pic"
          label="封面图"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 90px; height: 90px"
              :src="scope.row.cover_pic"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="goods_name" label="商品名称" align="center">
        </el-table-column>
        <el-table-column prop="spec" label="规格" align="center">
          <template slot-scope="scope">
            <div>
              <el-select
                v-model="scope.row.spec_id"
                placeholder="请选择"
                size="mini"
                @change="handleSpec(scope.$index, scope.row)"
              >
                <el-option
                  v-for="item in scope.row.specs"
                  :key="item.spec_id"
                  :label="item.attr_value"
                  :value="item.spec_id"
                >
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="数量" align="center">
          <template slot-scope="scope">
            <div
              style="display: flex;
								 justify-content: space-around"
            >
              <el-input
                size="mini"
                @change="$forceUpdate()"
                v-model="scope.row.number"
                placeholder="数量"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="goods_price" label="单价" align="center">
        </el-table-column>
        <el-table-column prop="goods_total_price" label="总计" align="center">
          <template slot-scope="scope">
            <div>
              {{ (scope.row.goods_price * scope.row.number).toFixed(2) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="discount_amount" label="优惠金额" align="center">
          <template slot-scope="scope">
            <div
              v-if="isEditAddGoodsDiscount"
              @change="
                handleAddSinglePrice(scope.index, scope.row, 'discount_amount')
              "
              style="display: flex; justify-content: space-around"
            >
              <el-input
                size="mini"
                v-model="scope.row.discount_amount"
                placeholder="优惠"
              ></el-input>
            </div>
            <span v-else>{{ scope.row.discount_amount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalSinglePrice" label="小计" align="center">
          <template slot-scope="scope">
            {{
              (
                scope.row.goods_price * scope.row.number -
                scope.row.discount_amount
              ).toFixed(2)
            }}
          </template>
        </el-table-column>
      </el-table>

      <pagination
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getListProduct"
      />

      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 10px; margin-bottom: 15px"
      >
        <el-button class="mgr-20" size="mini" @click="handleCloseAddProduct"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="handleAddProductionConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAdminGoodList,
  getAdminOrderCommons,
  putAdminOrder,
} from "@/api/order";
// import { CodeToText, regionData, TextToCode } from "element-china-area-data";
import Pagination from "@/components/Pagination";
import address from "@/utils/address.js";

export default {
  name: "DialogCusterOrder",
  components: { Pagination },
  props: {
    width: {
      // 弹出框的宽度配置
      type: String,
      default: "640",
    },
    isActiveDialogEditOrder: {
      type: Boolean,
      default: false,
    },
    infoOrder: {
      type: Object,
      default: () => {},
    },
    originOrder: {
      type: String,
      default: "customer",
    },
  },
  async created() {
    this.getListProduct();
    await this._getAdminOrderCommons(this.paramsOrder);
    this.order_status = this.infoOrder.order_status || "";
    let addressList = [];
    address.forEach((item) => {
      item.children.forEach((item1) => {
        addressList.push(item1);
      });
    });
    this.provincesOptions = addressList;
  },

  watch: {
    infoOrder: {
      handler(newVal, oldVal) {
        this.paramsOrder.id = newVal.id;
      },
      immediate: true,
      deep: true,
    },
  },
  //计算总价multipleSelection
  computed: {
    cssVars() {
      return {
        "--widthToOne": this.width - 50 + "px",
        "--widthToTwo": this.width / 2 - 40 + "px",
      };
    },
    //计算商品的总价和优惠价
    totalAllPrice() {
      var total = 0;
      this.goodInfo.forEach((item) => {
        total += item.number * item.goods_price;
      });
      return total.toFixed(2);
    },
    totalAddAllPrice() {
      var total = 0;
      this.multipleSelection.forEach((item) => {
        total += item.number * item.goods_price;
      });
      return total.toFixed(2);
    },
    totalAllPrice1() {
      var total = 0;
      this.goodInfo.forEach((item) => {
        total += item.number * item.goods_price - item.discount_amount;
      });
      return total.toFixed(2);
    },
    totalAddAllPrice1() {
      var total = 0;
      this.multipleSelection.forEach((item) => {
        total += item.number * item.goods_price - item.discount_amount;
      });
      return total.toFixed(2);
    },
  },
  data() {
    return {
      address_deil:'',//默认回显地址
      listLoading: false,
      paramsOrder: {
        // 重orderInfo获取order.id 传递参数, 获取订单详情
        id: 0,
      },
      shr: [], // 地址信息
      provincesOptions: [],
      props: {
        label: "name",
        value: "code",
      },
      isEditShippingPersonInfo: false,
      shippingName: "",
      shippingMobile: "",
      shippingAddress: "",
      shippingPostCode: "",
      isEditGoodsInfo: false, //修改商品信息
      discount_amount: "",
      goodsNum: "",
      selectedProvinces: ["110000", "110100", "110101"],
      personInfo: [
        {
          shr_name: "",
          shr_mobile: "",
          region_name: "",
          region_name_code: "",
          shr_address: "",
        },
      ],
      goodInfo: [],
      dialogVisible: false, //打开添加商品对话框
      addProductSearchParams: {}, // 打开添加商品对话框搜索
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      order_status: "",
      tableData: [], // 所有商品列表数据
      modifyGoodFlag: true,
      addGoodFlag: true,
      tableDataAddProduct: [], // totalAddAllPrice: 0, //	添加商品的总价
      multipleSelection: [], // 添加商品列表
      addGoodsDiscount: "", // 添加商品的优惠
      isEditAddGoodsDiscount: true,
    };
  },
  methods: {
    handleSpec(index, row) {
      console.log(index, row, 2222);
      this.tableData[index].attr_value = row.specs.find(
        (item) => item.spec_id == row.spec_id
      ).attr_value;
      this.tableData[index].goods_price = row.specs.find(
        (item) => item.spec_id == row.spec_id
      ).discount;
    },
    getListProduct(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      this.addProductSearchParams.page = this.listQuery.page;
      this.addProductSearchParams.page_size = this.listQuery.limit;
      this.addProductSearchParams.status = 1;
      this.addProductSearchParams.phar_id = this.infoOrder.phar_id; // 药房ID
      this.addProductSearchParams.pres_not = 0;
      this.addProductSearchParams.cate_id = 2;
      getAdminGoodList(this.addProductSearchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
            //在this.tableData里面每一项增加number属性,且双向绑定
            this.tableData.forEach((item) => {
              //双向绑定
              this.$set(item, "number", 1);
              this.$set(item, "discount_amount", 0);
              this.$set(item, "spec_id", item.specs[0].spec_id);
              this.$set(item, "attr_value", item.specs[0].attr_value);
              this.$set(item, "goods_price", item.specs[0].discount);
            });
            console.log(this.tableData);
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    async _getAdminOrderCommons(params) {
      try {
        var response = await getAdminOrderCommons(params);
        if (response.code == 200) {
          console.log(response, this.personInfo, 21312312);
          this.goodInfo = response.data.goods;
          this.shr = [response.data.shr];
          this.address_deil = response.data.shr.region_name
					this.maxDay = response.data.order.use_day
        } else {
          this.$message({
            type: "error",
            message: response.msg,
          });
        }
      } catch (e) {
        console.log(e);
        this.$message({
          type: "error",
          message: "网络问题",
        });
      } finally {
        this.listLoading = false;
      }
    },
    handleEditShippingPersonInfo() {
      this.isEditShippingPersonInfo = !this.isEditShippingPersonInfo;
    },

    handleEditGoodsInfo() {
      if (this.modifyGoodFlag) {
        this.isEditGoodsInfo = !this.isEditGoodsInfo;
      } else {
        this.$message({
          type: "error",
          message: "输入数量,折扣错误!!!",
        });
      }
    },
    handleAddGoods() {
      this.dialogVisible = true;
    },
    handleChange(e) {
      console.log(e, this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels);
      let region_name = this.$refs.cascaderAddr.getCheckedNodes()[0].pathLabels;
      this.shr[0].region_name = region_name.join("");
      console.log(this.shr);
    },
    handleDeleteGood(index, row) {
      this.goodInfo.splice(index, 1);
    },
    handleCancel(e) {
      this.isEditGoodsInfo = false;
      this.$emit("update:isActiveDialogEditOrder", false);
    },
    async handleConfirm(e) {
      var data = {};
      if (this.originOrder === "shop" || this.originOrder === "hospital") {
        data = {
          order: this.infoOrder.id,
          shr_name: this.shr[0].shr_name,
          shr_mobile: this.shr[0].shr_mobile,
          zip_code: this.personInfo[0].zip_code,
          region_name: this.shr[0].region_name,
          shr_address: this.shr[0].shr_address,
        };
      } else {
        data = {
          order: this.infoOrder.id,
          shr_name: this.shr[0].shr_name,
          shr_mobile: this.shr[0].shr_mobile,
          region_name: this.shr[0].region_name,
          zip_code: this.personInfo[0].zip_code,
          shr_address: this.shr[0].shr_address,
        };
      }
      console.log(data, this, 2222);
      // this.goodInfo 有所有的数据, 重新整理
      var goods = [];
      this.goodInfo.forEach((item) => {
        var _data = {
          id: item.id,
          spec_id: item.spec_id,
          number: Number(item.number),
          discount_amount: item.discount_amount,
        };
        goods.push(_data);
      });
      data.goods = goods;
      console.log(data, this, 2222);
      if (this.modifyGoodFlag) {
        await this._putAdminOrder(data);
        this.isEditGoodsInfo = false;
        this.$emit("getList");
        this.$emit("update:isActiveDialogEditOrder", false);
        // this.$emit('update:isActiveDialogEditOrder', false)
      } else {
        this.$message({
          type: "error",
          message: "输入数量,折扣错误!!!",
        });
      }
    },
    //订单审核请求
    async _putAdminOrder(data) {
      await putAdminOrder(this.paramsOrder.id, data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$emit("getList");
          this.$emit("update:isActiveDialogEditOrder", false);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    handleGoodInfoSinglePrice(index, row, type) {
      console.log(row);
      var reNum = /^[0-9]+$/;
      var reDec = /^\d+(\.\d{1,2})?$/;
      if (type == "number") {
        if (!reNum.test(row.number)) {
          this.modifyGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正整数",
          });
        }
      } else {
        if (!reDec.test(row.discount_amount)) {
          this.modifyGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正确的折扣",
          });
        }
        var subtotal = row.number * row.goods_price - row.discount_amount * 1;
        if (subtotal < 0) {
          this.modifyGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正确的折扣",
          });
        }
      }

      this.modifyGoodFlag = true;
    },
    handleCloseAddProduct() {
      this.isEditAddGoodsDiscount = true;
      this.multipleSelection = [];
      this.dialogVisible = false;
    },
    async handleAddProductionConfirm() {
      console.log(this.tableData, "2357");
      if (this.addGoodFlag) {
        console.log(this.multipleSelection);
        if (this.multipleSelection.length == 0) {
          return this.$message({
            type: "warning",
            message: "请选中商品",
          });
        }
        let goods = [];
        this.multipleSelection.forEach((item) => {
          let _data = {
            id: 0,
            spec_id: item.spec_id,
            number: item.number,
            discount_amount: item.discount_amount,
            img_url: item.cover_pic,
            goods_name: item.goods_name,
            spec: item.attr_value,
            goods_price: item.goods_price,
            amount: item.goods_price * item.number - item.discount_amount,
          };
          goods.push(_data);
        });
        //如果有重复的商品,则不添加
        goods.forEach((item) => {
          var flag = false;
          this.goodInfo.forEach((item1) => {
            if (item.spec_id == item1.spec_id) {
              item1.number = Number(item1.number) + Number(item.number);
              flag = true;
            }
          });
          if (!flag) {
            this.goodInfo.push(item);
          }
        });
        // this.goodInfo.push(goods);
        this.isEditAddGoodsDiscount = true;
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: "输入数量,折扣错误!!!",
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEditAddGoodsInfo() {
      this.isEditAddGoodsDiscount = true;
    },
    handleAddSinglePrice(index, row, type) {
      console.log(row);
      var reNum = /^[0-9]+$/;
      var reDec = /^\d+(\.\d{1,2})?$/;
      if (type == "number") {
        if (!reNum.test(row.number)) {
          this.addGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正整数",
          });
        }
      } else {
        if (!reDec.test(row.discount_amount)) {
          this.addGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正确的折扣",
          });
        }
        var subtotal = row.number * row.goods_price - row.discount_amount * 1;
        if (subtotal < 0) {
          this.addGoodFlag = false;
          return this.$message({
            type: "error",
            message: "输入正确的折扣",
          });
        }
      }

      this.addGoodFlag = true;
      var amount = row.number * row.goods_price - row.discount_amount * 1;
      row.amount = amount;
    },
  },
};
</script>

<style scoped lang="scss">
.region_name {
  color: #333333;
}
.input2 {
  /deep/.el-input__inner {
    &::placeholder {
      color: #333333;
    }
  }
}
/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*dialog对话框样式调整*/
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*竖杠+后面文本的样式*/
.required {
  margin-top: 2px;
  display: inline-block;
  width: 4px;
  height: 14px;
  background-color: #2362fb;
  top: 20px;
}

.required + span {
  display: inline-block;
  margin-left: 4px;
  vertical-align: top;
  font-weight: bold;
  font-size: 14px;
}

.mgr-20 {
  margin-right: 20px;
}
</style>
